<template>
  <!-- 用户切换页面 -->
  <div class="user-data">
    <!-- 用户切换或添加 -->
    <div class="user-data-background">
      <Scroll
        class="region-scroll"
        ref="scroll"
        :scrollX="true"
        :inline="true"
        :probeType="3"
      >
        <div class="user-state small-size">
          <span @click="$router.push('/login')">
            <svg-com
              class="add"
              href="#icon-add-fill-hover-fuyou"
              size="4.6rem"
            />
          </span>
          <div
            class="user-state-card"
            v-for="(item, index) in cardList"
            :style="{
              border: index == 0 ? '1px solid rgb(246, 156, 182)' : '',
            }"
            :key="index"
            @click="userClick(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </Scroll>
      <!-- 用户卡片 -->
      <div
        class="user-card"
        v-if="cardList[indexs] != undefined || cardList[indexs] != null"
      >
        <div class="user-card-top">
          <div class="user-imgs">
            <img class="user-img-tx" :src="headPortrait" alt="" />
          </div>
          <div class="user-name">
            <span class="name">{{ cardList[indexs].name }}</span>
            <span class="identity-card small-size" style="color:#8a8488">
              {{ cardList[indexs].cardNo }}
            </span>
          </div>
        </div>
        <div class="user-card-center">
          <div class="medId">
            <!-- 二维码 -->
            <vue-qr
              :text="cardList[indexs].cardNo"
              :size="200"
              bindElement
            ></vue-qr>
          </div>
          <!-- 身份证 -->
          <div class="center-identity-card">
            <span style="color:#8a8488">身份证号</span>
            <span>{{ getIdNo() }}</span>
          </div>
          <!-- 手机号 -->
          <div class="center-cell-phone-number">
            <span style="color:#8a8488">手机号</span>
            <span>{{ getPhone() }}</span>
          </div>
        </div>
        <div class="user-card-bottom" @click="userDelete">
          <span>删除此用户</span>
          <span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-youjiantou1-copy"></use>
            </svg>
          </span>
        </div>
      </div>
      <!-- 没有登录用户 -->
      <div v-else>
        <van-empty description="当前无绑卡"></van-empty>
      </div>
    </div>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
import { userDelete, updateTiedCardInfo } from '@/network/service'
import Scroll from '@/components/content/scroll/BScroll'
import { Debounce } from '@/common/utils'
import { mapState } from 'vuex'
export default {
  name: 'user-data',
  components: {
    Scroll,
    vueQr,
  },
  data() {
    return {
      //第一个用户
      indexs: 0,
    }
  },
  mounted() {
    //获取绑卡信息
    this.cardBinding()
  },
  computed: {
    ...mapState(['cardList', 'headPortrait']),
    refresh() {
      return this.cardList.length
    },
  },
  watch: {
    refresh: function() {
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    },
  },
  methods: {
    //获取绑卡信息
    cardBinding(name, _this) {
      //用户信息请求
      this.$store.commit('USER_POST', { name, _this })
    },
    //用户切换
    userClick: Debounce(async function(item, index) {
      if (index != this.indexs) {
        //回到原始位置
        this.$refs.scroll.scrollTo(0, 0, 300)
        //修改用户绑卡信息
        await updateTiedCardInfo({
          id: item.id,
        })
        this.$store.state.adopt = true
        //用户信息请求
        this.cardBinding()
      }
    }, 200),
    //删除用户
    userDelete() {
      let { open_id } = this.$Cookies.get()
      this.$Dialog
        .confirm({
          title: '用户',
          message: '确认删除?',
        })
        .then(async () => {
          //删除用户
          let res = await userDelete({
            open_id,
            id: this.cardList[this.indexs].id,
          })
          this.$store.state.adopt = true
          //确认删除
          if (res.code == '0') {
            //获取绑卡信息
            this.cardBinding('delete', this)
            this.$nextTick(() => {
              //回到原始位置
              this.$refs.scroll.scrollTo(0, 0, 300)
            })
          } else {
            this.$toast(res.msg)
          }
        })
        .catch(() => {})
    },
    getIdNo() {
      let idno = this.$Base64.decode(
        this.$store.state.cardList[this.indexs].idNo
      )
      return idno.slice(0, 6) + '********' + idno.slice(14, 18)
    },
    getPhone() {
      let phone = this.$Base64.decode(
        this.$store.state.cardList[this.indexs].phone
      )
      return phone.slice(0, 3) + '****' + phone.slice(7, 11)
    },
  },
}
</script>

<style lang="less" scoped>
.bottom-button {
  width: 10rem;
  height: 3rem;
  background-color: @darkColor;
  border: 1px solid @darkColor;
}
.user-data {
  height: 100vh;
  overflow: hidden;
  .user-data-background {
    height: 100%;
    .region-scroll {
      width: calc(100vw - 2rem);
      overflow: hidden;
      .user-state {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        margin-left: 1rem;
        white-space: nowrap;
        .user-state-card {
          display: inline-block;
          flex-shrink: 0;
          text-align: center;
          margin-left: 1rem;
          height: 4rem;
          width: 4rem;
          line-height: 4rem;
          border-radius: 50%;
          background-color: #ffffff;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .user-card {
      border-radius: 1rem;
      margin: 2rem 1rem 0 1rem;
      // height: 15rem;
      background-color: #ffffff;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
      .user-card-top {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d9d9d9;
        line-height: 5rem;
        padding-left: 1rem;
        .user-imgs {
          padding-right: 1rem;
          .user-img-tx {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
          }
        }
        .user-name {
          display: flex;
          flex-direction: column;
          padding-top: 0.5rem;
          span {
            line-height: 1.5rem;
          }
        }
      }
      .user-card-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #d9d9d9;
        .medId {
          width: 200px;
          margin: auto;
        }
        // height: 15rem;
        .center-identity-card,
        .center-cell-phone-number {
          display: flex;
          justify-content: space-between;
          padding: 0 1rem;
          line-height: 2rem;
        }
      }
      .user-card-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.75rem;
        padding: 0 1rem;
      }
    }
  }
}
</style>
